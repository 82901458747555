// @flow

import React, { useEffect } from 'react';
import type { Node } from 'react';
import { components as CONFIG_COMPONENTS } from 'Config';
import { useLocale } from 'shared_services/redux/hooks/useLocale';

import {
  raColorText,
  raColorGrayscale30,
} from '@riseart/antd-provider/dist/website/variables.less';
import { artReviewsWidgetId } from 'shared_components/arts/reviews/Reviews.less';

type Props = {
  id?: ?string,
  options?: Object,
};

/**
 * ArtReviews
 *
 * @param {Props} props
 * @returns
 */
export function ArtReviews({ id = artReviewsWidgetId, options = {} }: Props): Node {
  const lang = useLocale('language');
  const SCRIPT_ID = 'artwork-review-script';
  const DEFAULT_WIDGET_OPTIONS = {
    /* Your REVIEWS.io account ID: */
    store: 'riseart-com',
    sku: '',
    lang,
    carousel_type: 'default',
    styles_carousel: 'CarouselWidget--sideHeader',

    /* Widget settings: */
    options: {
      general: {
        /* What reviews should the widget display? Available options: company, product, third_party. You can choose one type or multiple separated by comma. */
        review_type: 'company, product',
        /* Minimum number of reviews required for widget to be displayed */
        min_reviews: '1',
        /* Maximum number of reviews to include in the carousel widget. */
        max_reviews: '20',
        address_format: 'CITY, COUNTRY',
        /* Carousel auto-scrolling speed. 3000 = 3 seconds. If you want to disable auto-scroll, set this value to false. */
        enable_auto_scroll: false,
        /* Auto generated ldjson schema from the entered data in profile */
        enable_richsnippets_company: false,
        enable_richsnippets_product: false,
      },
      header: {
        /* Show overall rating stars */
        enable_overall_stars: true,
        rating_decimal_places: 2,
      },
      reviews: {
        /* Show customer name */
        enable_customer_name: true,
        /* Show customer location */
        enable_customer_location: false,
        /* Show "verified review" badge */
        enable_verified_badge: false,
        /* Show "verified subscriber" badge */
        enable_subscriber_badge: false,
        /* Show "I recommend this product" badge (Only for product reviews) */
        enable_recommends_badge: false,
        /* Show photos attached to reviews */
        enable_photos: false,
        /* Show videos attached to reviews */
        enable_videos: true,
        /* Show when review was written */
        enable_review_date: true,
        /* Hide reviews written by the same customer (This may occur when customer reviews multiple products) */
        disable_same_customer: true,
        /* Minimum star rating */
        min_review_percent: 4,
        /* Show 3rd party review source */
        third_party_source: true,
        /* Hide reviews without comments (still shows if review has a photo) */
        hide_empty_reviews: true,
        /* Show product name */
        enable_product_name: true,
        /* Show only reviews which have specific tags (multiple semicolon separated tags allowed i.e tag1;tag2) */
        tags: lang,
        /* Show branch, only one input */
        branch: '',
        enable_branch_name: false,
      },
      popups: {
        /* Make review items clickable (When they are clicked, a popup appears with more information about a customer and review) */
        enable_review_popups: true,
        /* Show "was this review helpful" buttons */
        enable_helpful_buttons: true,
        /* Show how many times review was upvoted as helpful */
        enable_helpful_count: true,
        /* Show share buttons */
        enable_share_buttons: true,
      },
    },
    translations: {
      verified_customer: 'Verified Customer',
    },
    styles: {
      /* Base font size is a reference size for all text elements. When base value gets changed, all TextHeading and TexBody elements get proportionally adjusted. */
      '--base-font-size': '16px',
      '--base-maxwidth': '100%',

      /* Logo styles: */
      '--reviewsio-logo-style': 'var(--logo-normal)',

      /* Star styles: */
      '--common-star-color': '#f47e27',
      '--common-star-disabled-color': ' rgba(0,0,0,0.25)',
      '--medium-star-size': ' 22px',
      '--small-star-size': '19px' /* Modal */,
      '--x-small-star-size': '21px',
      '--x-small-star-display': 'inline-flex',

      /* Header styles: */
      '--header-order': '1',
      '--header-width': '160px',
      '--header-bg-start-color': 'transparent',
      '--header-bg-end-color': 'transparent',
      '--header-gradient-direction': '135deg',
      '--header-padding': '0.5em',
      '--header-border-width': '0px',
      '--header-border-color': 'rgba(0,0,0,0.1)',
      '--header-border-radius': '0px',
      '--header-shadow-size': '0px',
      '--header-shadow-color': 'rgba(0, 0, 0, 0.1)',

      /* Header content styles: */
      '--header-star-color': 'inherit',
      '--header-disabled-star-color': 'inherit',
      '--header-heading-text-color': raColorText,
      '--header-heading-font-size': '24px',
      '--header-heading-font-weight': '400',
      '--header-heading-line-height': 'inherit',
      '--header-heading-text-transform': 'inherit',
      '--header-subheading-text-color': raColorText,
      '--header-subheading-font-size': 'inherit',
      '--header-subheading-font-weight': 'inherit',
      '--header-subheading-line-height': 'inherit',
      '--header-subheading-text-transform': 'inherit',

      /* Review item styles: */
      '--item-maximum-columns': '5' /* Must be 3 or larger */,
      '--item-background-start-color': 'transparent',
      '--item-background-end-color': 'transparent',
      '--item-gradient-direction': '135deg',
      '--item-padding': '0.5em',
      '--item-border-width': '0px',
      '--item-border-color': 'rgba(0,0,0,0.1)',
      '--item-border-radius': '0px',
      '--item-shadow-size': '0px',
      '--item-shadow-color': 'rgba(0,0,0,0.1)',

      /* Heading styles: */
      '--heading-text-color': raColorText,
      '--heading-text-font-weight': '500',
      '--heading-text-font-family': ' inherit',
      '--heading-text-line-height': ' 1.4',
      '--heading-text-letter-spacing': '0',
      '--heading-text-transform': 'uppercase',

      /* Body text styles: */
      '--body-text-color': raColorText,
      '--body-text-font-weight': '400',
      '--body-text-font-family': ' inherit',
      '--body-text-line-height': ' 1.4',
      '--body-text-letter-spacing': '0',
      '--body-text-transform': 'none',

      /* Scroll button styles: */
      '--scroll-button-icon-color': raColorGrayscale30,
      '--scroll-button-icon-size': '44px',
      '--scroll-button-bg-color': 'transparent',

      '--scroll-button-border-width': '0px',
      '--scroll-button-border-color': 'rgba(0,0,0,0.1)',

      '--scroll-button-border-radius': '60px',
      '--scroll-button-shadow-size': '0px',
      '--scroll-button-shadow-color': 'rgba(0,0,0,0.1)',
      '--scroll-button-horizontal-position': '0px',
      '--scroll-button-vertical-position': '0px',

      /* Badge styles: */
      '--badge-icon-color': '#0E1311',
      '--badge-icon-font-size': '15px',
      '--badge-text-color': raColorText,
      '--badge-text-font-size': 'inherit',
      '--badge-text-letter-spacing': 'inherit',
      '--badge-text-transform': 'inherit',

      /* Author styles: */
      '--author-font-size': '14px',
      '--author-font-weight': '500',
      '--author-text-transform': 'capitalize',

      /* Product photo or review photo styles: */
      '--photo-video-thumbnail-size': '60px',
      '--photo-video-thumbnail-border-radius': '0px',

      /* Popup styles: */
      '--popup-backdrop-color': 'rgba(0,0,0,0.75)',
      '--popup-color': '#ffffff',
      '--popup-star-color': 'inherit',
      '--popup-disabled-star-color': 'inherit',
      '--popup-heading-text-color': raColorText,
      '--popup-body-text-color': raColorText,
      '--popup-badge-icon-color': 'inherit',
      '--popup-badge-icon-font-size': '19px',
      '--popup-badge-text-color': raColorText,
      '--popup-badge-text-font-size': '14px',
      '--popup-border-width': '0px',
      '--popup-border-color': 'rgba(0,0,0,0.1)',
      '--popup-border-radius': '0px',
      '--popup-shadow-size': '0px',
      '--popup-shadow-color': 'rgba(0,0,0,0.1)',
      '--popup-icon-color': '#0E1311',

      /* Tooltip styles: */
      '--tooltip-bg-color': '#0E1311',
      '--tooltip-text-color': raColorText,
    },
  };

  useEffect(() => {
    // Add main styles
    const REVIEWS_CSS_COMMON = 'reviews-styles-common';
    const commonStylesTag = document.getElementById(REVIEWS_CSS_COMMON);

    if (!commonStylesTag) {
      const linkTagCommon = document.createElement('link');
      linkTagCommon.id = REVIEWS_CSS_COMMON;
      linkTagCommon.rel = 'stylesheet';
      linkTagCommon.href = CONFIG_COMPONENTS.reviewWidget.cssCommon;
      document.head && document.head.appendChild(linkTagCommon);
    }

    // Add icons styles
    const REVIEWS_CSS_ICONS = 'reviews-styles-icons';
    const iconsStylesTag = document.getElementById(REVIEWS_CSS_ICONS);

    if (!iconsStylesTag) {
      const linkTagIcons = document.createElement('link');
      linkTagIcons.id = REVIEWS_CSS_ICONS;
      linkTagIcons.rel = 'stylesheet';
      linkTagIcons.href = CONFIG_COMPONENTS.reviewWidget.cssIcons;
      document.head && document.head.appendChild(linkTagIcons);
    }
  }, []);

  useEffect(() => {
    if (document.getElementById(SCRIPT_ID) && window.carouselInlineWidget) {
      // eslint-disable-next-line
      new window.carouselInlineWidget(id, { ...DEFAULT_WIDGET_OPTIONS, ...options });
      return;
    }

    const script = document.createElement('script');
    script.id = SCRIPT_ID;
    script.type = 'text/javascript';
    script.src = CONFIG_COMPONENTS.reviewWidget.source;
    script.async = true;
    document.body && document.body.appendChild(script);

    script.addEventListener('load', () => {
      // eslint-disable-next-line
      new window.carouselInlineWidget(id, { ...DEFAULT_WIDGET_OPTIONS, ...options });
    });
    // eslint-disable-next-line
  }, [id, options, lang]);

  return <div id={id} />;
}
